<script setup lang="ts">
import { ROUTES } from '~/constants/routes';

const config = useRuntimeConfig();
const favouritesUrl = config.public.favouritesUrl;

defineProps({
    userId: { type: String, required: true }
})
</script>

<template>
    <div class="menu-header">
        <label class="menu-header-text">{{ $t('routes.menu') }}</label>
    </div>
    <div class="menu-items">
        <NuxtLink 
            :to="ROUTES.user(userId)" 
            activeClass="active" 
            class="menu-item-link"
        >
            <i class="menu-item-icon ri-user-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.profile') }}</span>
        </NuxtLink>
        <span class="divider"/>
        <NuxtLink 
            :to="ROUTES.bookingClient" 
            activeClass="active" 
            class="menu-item-link"
        >
            <i class="menu-item-icon ri-home-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.bookings') }}</span>
        </NuxtLink>
        <span class="divider"/>
        <NuxtLink 
            :to="ROUTES.settlementClient" 
            activeClass="active" 
            class="menu-item-link"
        >
            <i class="menu-item-icon ri-key-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.settlement') }}</span>
        </NuxtLink>
        <span class="divider"/>
        <NuxtLink 
            :to="ROUTES.partners" 
            activeClass="active" 
            class="menu-item-link"
        >
            <i class="menu-item-icon ri-vip-crown-2-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.partners') }}</span>
        </NuxtLink>
        <span class="divider"/>
        <a :href="favouritesUrl" class="menu-item-link">
            <i class="menu-item-icon ri-heart-3-line ri-lg"/>
            <span class="menu-item-text">{{ $t('routes.favorites') }}</span>
            <i class="menu-item-icon mobile-visible ri-arrow-right-up-line ri-lg"/>
        </a>
    </div>
</template>

<style scoped lang="scss">
.menu-header {
    display: flex;
    padding: 0 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    /** Заголовки в меню для мобильной версии скрываются */
    @include responsive( 'mobile' ) {
        display: none;
    }

    .menu-header-text {
        color: var(--Color-Zink-400, $main-text-color);

        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.13px;
    }
}

.menu-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    @include responsive( 'mobile' ) {
        flex-direction: row;
    }

    .menu-item-link {
        display: flex;
        padding: 10px 12px;
        align-items: center;
        gap: 8px;

        @include responsive( 'mobile' ) {
            margin: 12px 8px 0px 8px;
            padding: 0px 0px 20px 0px;
        }

        .menu-item-icon {
            width: 20px;
            height: 20px;
            color: $main-text-color;

            @include responsive( 'mobile' ) {
                display: none;
            }

            &.mobile-visible{
                @include responsive( 'mobile' ) {
                    display: block;
                }
            }
        }

        .menu-item-text {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;
            color: $main-text-color;
        }

        &.active {
            @include responsive( 'mobile' ) {
                border-bottom: 1px solid $hover-link-color;
            }

            .menu-item-icon {
                color: $main-active-color;
            }
            .menu-item-text {
                color: $main-active-color;
            }
        }
    }
}
</style>